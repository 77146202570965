export const CardHeading = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <h4
      className={
        "font-av text-center text-xl font-bold leading-7 md:text-2xl md:leading-8 lg:text-3xl lg:leading-10" +
        " " +
        className
      }>
      {children}
    </h4>
  ) : null;
};
