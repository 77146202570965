export const SubHeading = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <h2
      className={
        "font-av text-[25px] font-medium leading-[34px] md:text-[35px] md:leading-[46px] lg:text-[45px] lg:leading-[61px]" +
        " " +
        className
      }>
      {children}
    </h2>
  ) : null;
};
