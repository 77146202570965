import React from "react";

import { HeadSeo } from "@calcom/ui";

interface LandingPageProps {
  children: React.ReactNode;
  title: string;
  description: string;
}

export function LandingPage({ children, title, description }: LandingPageProps) {
  return (
    <>
      <HeadSeo title={title} description={description} />
      <svg style={{ visibility: "hidden" }} className="absolute -z-10">
        <defs>
          <linearGradient id="brandGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="var(--brand-blue)" />
            <stop offset="100%" stopColor="var(--brand-green)" />
          </linearGradient>
        </defs>
      </svg>
      <div className="flex min-h-screen flex-col bg-white">{children}</div>
    </>
  );
}
