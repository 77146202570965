import Link from "next/link";

import { SubHeading } from "./typography";

export type CallToActionProps = {
  text: string;
  ctaLink: string;
  ctaText: string;
  targetBlank?: boolean;
};

export const CallToAction = ({ text, ctaText, ctaLink, targetBlank }: CallToActionProps) => {
  return (
    <div className="m-auto flex w-full flex-col items-center justify-center md:flex-row">
      <div className="mb-5 w-full text-center md:mb-0 md:w-1/2 md:text-left">
        <SubHeading>{text}</SubHeading>
      </div>
      <div className="flex w-full justify-center text-center md:w-1/2 md:text-right">
        <Link
          href={ctaLink}
          className="font-av border-stprimary-orange-900 bg-stprimary-orange-900 hover:stprimary-orange-900 hover:text-stprimary-orange-900 inline-block rounded border-2 px-7 py-2 text-center text-base font-bold text-white hover:bg-white md:px-16 md:py-3 md:text-xl lg:py-5 lg:text-[32px]"
          target={targetBlank ? "_blank" : ""}
          rel={targetBlank ? "noreferrer" : ""}
          title={ctaText}>
          {ctaText}
        </Link>
      </div>
    </div>
  );
};
