import { Paragraph, SubHeading, Translate } from "@stss/ui";
import React from "react";

export type WhatWeDoItem = {
  i18key: string;
  icon: string;
};

export const WhatWeDo = ({ items }: { items: WhatWeDoItem[] }) => {
  const iconPath = "/st/img/icons/";

  return (
    <div>
      <SubHeading className="mb-2 text-center md:mb-4 lg:mb-5">
        <Translate i18nKey="what_we_do_title" />
      </SubHeading>
      <Paragraph className="text-center">
        <Translate i18nKey="what_we_do_sub_title" />
      </Paragraph>
      <div className="mt-3 columns-1 md:mt-5 lg:mt-12 lg:columns-2">
        {items.map((item, i) => (
          <div className="mb-3 flex flex-col items-center p-3 md:flex-row lg:mb-8" key={i}>
            <div className="mb-5 mr-3 aspect-square w-11 shrink-0 sm:mr-5 md:mb-0 md:w-16 lg:mr-8 lg:w-[73px]">
              <img src={iconPath + item.icon} alt={item.i18key} />
            </div>
            <Paragraph className="flex-auto flex-grow text-center md:text-left">
              <Translate i18nKey={item.i18key} />
            </Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
};
