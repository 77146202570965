import type { SharedVisitSettings, VisitSettings, AnswersKey } from "@stss/types";
import type { TFunction } from "next-i18next";
import type { Program } from "pages/visit-completed/[booking_id]";

import { visitStatusTranslations } from "./constants";

/**
 impossible cases for visit settings (by length and by associated joint)
 was described by DJ here: https://app.asana.com/0/1202369936408646/1205425986587156
 impossible case #1 - 30min with joint
 impossible case #2 - 40min without joint
 */

const userAnswersColors: Record<AnswersKey, string> = {
  asa_survey_answers: "green",
  onboarding_survey_answers: "blue",
};

const sharedVisitSettings: SharedVisitSettings = {
  allowedVisitStatusesForMedicallyClearing: ["medically_cleared", "unqualified"],
};

const visitSettingsByAssociatedJointAndLength: { hasJoint: VisitSettings[]; noJoint: VisitSettings[] } = {
  // settings for visits by length with no associated joint
  noJoint: [
    {
      length: 15,
      selectOptions: {
        visitOptions: [
          { value: "completed", label: visitStatusTranslations.completed },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: false,
      ...sharedVisitSettings,
    },
    {
      length: 30,
      selectOptions: {
        visitOptions: [
          { value: "completed", label: visitStatusTranslations.completed },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: false,
      ...sharedVisitSettings,
    },
    {
      length: 40,
      selectOptions: {
        visitOptions: [
          { value: "completed", label: visitStatusTranslations.completed },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: false,
      ...sharedVisitSettings,
    },
  ],
  // settings for visits by length with an associated joint
  hasJoint: [
    {
      length: 15,
      selectOptions: {
        visitOptions: [
          { value: "allow_pt", label: visitStatusTranslations.allow_pt },
          { value: "unqualified", label: visitStatusTranslations.unqualified },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: true,
      ...sharedVisitSettings,
    },
    {
      length: 30,
      selectOptions: {
        visitOptions: [
          { value: "medically_cleared", label: visitStatusTranslations.medically_cleared },
          { value: "unqualified", label: visitStatusTranslations.unqualified },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: true,
      ...sharedVisitSettings,
    },
    {
      length: 40,
      selectOptions: {
        visitOptions: [
          { value: "medically_cleared", label: visitStatusTranslations.medically_cleared },
          { value: "unqualified", label: visitStatusTranslations.unqualified },
          { value: "missed", label: visitStatusTranslations.missed },
        ],
        jointOptions: [],
      },
      allowMedicallyClearPrograms: true,
      ...sharedVisitSettings,
    },
  ],
};

export function getUserAnswersColor(key: AnswersKey): string {
  return userAnswersColors[key] || "orange";
}

// see above the impossible cases for visit settings
export function getVisitSettingsByAssociatedJointAndLength(
  program: Program | undefined,
  visitLength: number | undefined,
  tFunction?: TFunction
): VisitSettings | undefined {
  // if no visit length is provided, return empty settings
  if (!visitLength) {
    return getEmptySettings("Visit length is not provided");
  }
  // impossible case #1
  if (visitLength === 30 && program) {
    return getEmptySettings("30min visit length is not allowed for visits with associated joint");
  }
  // impossible case #2
  if (visitLength === 40 && !program) {
    return getEmptySettings("This type of visit must have an associated joint");
  }
  const settings = program
    ? visitSettingsByAssociatedJointAndLength.hasJoint.find((settings) => settings.length === visitLength)
    : visitSettingsByAssociatedJointAndLength.noJoint.find((settings) => settings.length === visitLength);

  /**
   *  if associated joint status is "onboarded" and visit length is 40min
   * we need to do the following:
   * 1. remove "medically_cleared" and "unqualified" visit status options
   * 2. add "completed" visit status option
   */
  if (settings && visitLength === 40 && program && program.status === "onboarded") {
    // remove "medically_cleared" ans "unqualified" visit status options
    const changedOptions = settings.selectOptions.visitOptions.filter(
      (opt) => opt.value !== "medically_cleared" && opt.value !== "unqualified"
    );

    // adding "completed" visit status option
    if (!changedOptions.find((opt) => opt.value === "completed")) {
      changedOptions.push({
        value: "completed",
        label: visitStatusTranslations.completed,
      });
    }
    settings.selectOptions.visitOptions = changedOptions;
  }

  return translateSettings(settings, tFunction);
}

function translateSettings(
  settings: VisitSettings | undefined,
  tFunction?: TFunction
): VisitSettings | undefined {
  if (settings && tFunction) {
    settings.selectOptions.visitOptions = settings.selectOptions.visitOptions.map((option) => ({
      ...option,
      label: tFunction(option.label),
    }));
    settings.selectOptions.jointOptions = settings.selectOptions.jointOptions.map((option) => ({
      ...option,
      label: tFunction(option.label),
    }));
  }
  return settings;
}

function getEmptySettings(notValidMessage?: VisitSettings["notValidMessage"]): VisitSettings {
  return {
    length: undefined,
    notValidMessage,
    selectOptions: {
      visitOptions: [],
      jointOptions: [],
    },
    allowMedicallyClearPrograms: false,
    ...sharedVisitSettings,
  };
}

export const hasSavedKey = (key: string) => {
  try {
    return !!localStorage.getItem(key);
  } catch (e) {
    return false;
  }
};
