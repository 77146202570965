import Link from "next/link";

export const SocialLinks = () => {
  return (
    <div className="flex items-center justify-center">
      <Link
        className="mx-3"
        href="https://www.instagram.com/simpletherapy"
        target="_blank"
        rel="noreferrer"
        title="SimpleTherapy instagram">
        <svg
          className="fill-stprimary-blue hover:fill-stprimary-orange-900"
          xmlns="http://www.w3.org/2000/svg"
          width="26.297"
          height="26.297"
          viewBox="0 0 26.297 26.297">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M13.148,2.337a40.316,40.316,0,0,1,5.259.146,6.776,6.776,0,0,1,2.484.438,5.131,5.131,0,0,1,2.484,2.484,6.776,6.776,0,0,1,.438,2.484c0,1.315.146,1.753.146,5.259a40.315,40.315,0,0,1-.146,5.259,6.776,6.776,0,0,1-.438,2.484,5.131,5.131,0,0,1-2.484,2.484,6.776,6.776,0,0,1-2.484.438c-1.315,0-1.753.146-5.259.146a40.315,40.315,0,0,1-5.259-.146,6.776,6.776,0,0,1-2.484-.438,5.131,5.131,0,0,1-2.484-2.484,6.776,6.776,0,0,1-.438-2.484c0-1.315-.146-1.753-.146-5.259a40.316,40.316,0,0,1,.146-5.259,6.776,6.776,0,0,1,.438-2.484A5.247,5.247,0,0,1,3.945,3.945,2.47,2.47,0,0,1,5.405,2.922a6.776,6.776,0,0,1,2.484-.438,40.316,40.316,0,0,1,5.259-.146m0-2.337A43.167,43.167,0,0,0,7.743.146,9.019,9.019,0,0,0,4.529.73,5.719,5.719,0,0,0,2.191,2.191,5.719,5.719,0,0,0,.73,4.529,6.657,6.657,0,0,0,.146,7.743,43.167,43.167,0,0,0,0,13.148a43.167,43.167,0,0,0,.146,5.405A9.019,9.019,0,0,0,.73,21.768a5.719,5.719,0,0,0,1.461,2.337,5.719,5.719,0,0,0,2.337,1.461,9.019,9.019,0,0,0,3.214.584,43.167,43.167,0,0,0,5.405.146,43.167,43.167,0,0,0,5.405-.146,9.019,9.019,0,0,0,3.214-.584,6.129,6.129,0,0,0,3.8-3.8,9.019,9.019,0,0,0,.584-3.214c0-1.461.146-1.9.146-5.405a43.167,43.167,0,0,0-.146-5.405,9.019,9.019,0,0,0-.584-3.214,5.719,5.719,0,0,0-1.461-2.337A5.719,5.719,0,0,0,21.768.73,9.019,9.019,0,0,0,18.554.146,43.167,43.167,0,0,0,13.148,0m0,6.428a6.612,6.612,0,0,0-6.72,6.72,6.72,6.72,0,1,0,6.72-6.72m0,11.1a4.3,4.3,0,0,1-4.383-4.383,4.3,4.3,0,0,1,4.383-4.383,4.3,4.3,0,0,1,4.383,4.383,4.3,4.3,0,0,1-4.383,4.383m7.012-13a1.607,1.607,0,1,0,1.607,1.607,1.621,1.621,0,0,0-1.607-1.607"
            fillRule="evenodd"
          />
        </svg>
      </Link>
      <Link
        href="https://twitter.com/simpletherapy"
        className="mx-3"
        target="_blank"
        rel="noreferrer"
        title="SimpleTherapy twitter">
        <svg
          className="fill-stprimary-blue hover:fill-stprimary-orange-900"
          xmlns="http://www.w3.org/2000/svg"
          width="26.297"
          height="21.33"
          viewBox="0 0 26.297 21.33">
          <path
            id="Path_2"
            data-name="Path 2"
            d="M46.327,23.33A15.2,15.2,0,0,0,61.667,7.99v-.73A11.875,11.875,0,0,0,64.3,4.484a12.128,12.128,0,0,1-3.068.877,5.684,5.684,0,0,0,2.338-2.922,13.4,13.4,0,0,1-3.36,1.315A5.217,5.217,0,0,0,56.262,2a5.489,5.489,0,0,0-5.405,5.405A2.848,2.848,0,0,0,51,8.574a15.1,15.1,0,0,1-11.1-5.7,5.6,5.6,0,0,0-.73,2.776,5.8,5.8,0,0,0,2.337,4.529,4.925,4.925,0,0,1-2.484-.73h0a5.339,5.339,0,0,0,4.383,5.259,4.5,4.5,0,0,1-1.461.146,2.486,2.486,0,0,1-1.023-.146,5.535,5.535,0,0,0,5.113,3.8,11.03,11.03,0,0,1-6.72,2.337A4.045,4.045,0,0,1,38,20.7a13.789,13.789,0,0,0,8.327,2.63"
            transform="translate(-38 -2)"
            fillRule="evenodd"
          />
        </svg>
      </Link>
      <Link
        href="https://www.facebook.com/simpletherapy"
        className="mx-3"
        target="_blank"
        rel="noreferrer"
        title="SimpleTherapy facebook">
        <svg
          className="fill-stprimary-blue hover:fill-stprimary-orange-900"
          xmlns="http://www.w3.org/2000/svg"
          width="13.733"
          height="26.297"
          viewBox="0 0 13.733 26.297">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M88.912,26.3V14.317H93l.584-4.675H88.912V6.72c0-1.315.438-2.337,2.337-2.337h2.484V.146C93.148.146,91.688,0,90.08,0c-3.506,0-5.99,2.191-5.99,6.136V9.642H80v4.675h4.091V26.3Z"
            transform="translate(-80)"
            fillRule="evenodd"
          />
        </svg>
      </Link>
    </div>
  );
};
