import classNames from "classnames";
import type { ReactNode } from "react";
import React, { useState, useEffect } from "react";

interface SlideOutBannerProps {
  children: ReactNode;
  position?: "top" | "bottom";
  headerRef: React.MutableRefObject<HTMLElement | null>;
}

export const SlideOutBanner = ({ children, position = "bottom", headerRef }: SlideOutBannerProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const calculateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    calculateHeaderHeight();

    const resizeObserver = new ResizeObserver(() => {
      calculateHeaderHeight();
    });

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const bannerPositionStyles = position === "top" ? { top: `${headerHeight}px` } : { bottom: "0px" };

  return (
    <div
      className={classNames(
        "bg-stprimary-orange-900 fixed left-0 z-[1000] w-full  p-4 text-white transition-transform duration-500 ease-in-out",
        isOpen ? "translate-y-0" : position === "top" ? "-translate-y-[200%]" : "translate-y-full",
        position === "top" ? "rounded-b-lg" : "rounded-t-lg"
      )}
      style={bannerPositionStyles}>
      <div className="container mx-auto flex items-center justify-between">
        <div>{children}</div>
        <button
          onClick={handleClose}
          className="group absolute right-4 top-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full border-[2px] border-white bg-transparent transition-all duration-300 hover:border-red-700 hover:bg-red-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6 text-white transition-all duration-300 group-hover:text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};
