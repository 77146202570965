import { Translate } from "@stss/ui";

import { SvgSeparator } from "./SvgSeparator";
import { SubHeading, Paragraph } from "./typography";

export const HelpMembersSeeResult = () => {
  const getTextContent = () => {
    return (
      <>
        <SubHeading className="mb-2 md:mb-4 lg:mb-6">
          <Translate i18nKey="help_members_title" />
        </SubHeading>
        <Paragraph>
          <Translate i18nKey="help_members_message" />
        </Paragraph>
      </>
    );
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="relative hidden md:block">
          <div className="grid grid-cols-2">
            <div
              className="min-h-[110vh] bg-cover bg-bottom bg-no-repeat"
              style={{ backgroundImage: "url(/st/img/home_mockup-increased-min.jpg)" }}
            />
            <div className="bg-stBackground-lightBlue relative flex items-center">
              <div className="bg-stBackground-lightBlue absolute left-[-10%] top-1/2 h-[750vw] w-[750vw] translate-y-[-50%] rounded-full lg:h-[450vw] lg:w-[450vw] xl:h-[300vw] xl:w-[300vw] 2xl:h-[200vw] 2xl:w-[200vw]" />
              <div className="relative z-10 px-[6vw] py-10">{getTextContent()}</div>
            </div>
          </div>
          <SvgSeparator
            fill="var(--background-color)"
            className="absolute bottom-0 left-0 z-20 h-[100px] scale-x-[-1]"
          />
        </div>
        <div className="block md:hidden">
          <div
            className="min-h-[130vw] bg-cover bg-bottom bg-no-repeat sm:min-h-[100vw]"
            style={{ backgroundImage: "url(/st/img/home_mockup-increased-min.jpg)" }}
          />
          <div className="relative m-auto max-w-xs text-center sm:max-w-sm">
            <div className="bg-stBackground-light absolute left-[70%] top-0 h-[250vw] w-[250vw] translate-x-[-50%] translate-y-[-8%] rounded-full sm:left-[80%]" />
            <div className="relative z-20 px-3">{getTextContent()}</div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <img
          className="m-auto max-h-[400px] md:max-h-[800px]"
          src="/st/img/smartphones_mockup-min.png"
          alt="Smartphone"
        />
      </div>
    </>
  );
};
