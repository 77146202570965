import { Translate } from "@stss/ui";
import React from "react";

import { CircularProgressCard } from "./CircularProgressCard";
import { SubHeading } from "./typography";

export type Outcome = {
  value: number;
  iconSrc: string;
  text: React.ReactNode;
};

export const MemberReportOutcomes = ({ outcomes }: { outcomes: Outcome[] }) => {
  const iconPath = "/st/img/icons/";

  return (
    <div className="m-auto max-w-[1380px]">
      <div className="mb:mb-8 mb-6 text-center lg:mb-12">
        <SubHeading>
          <Translate i18nKey="outcomes_title" />
        </SubHeading>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        {outcomes.map((outcome: Outcome) => (
          <CircularProgressCard
            key={outcome.value}
            progressValue={outcome.value}
            iconSrc={iconPath + outcome.iconSrc}
            textNode={outcome.text}
          />
        ))}
      </div>
    </div>
  );
};
