import type { UserJoint } from "@stss/types";
import { useTranslation } from "next-i18next";
import React, { useState, useEffect } from "react";

import type { AdditionalPatientInfoProps } from "./AdditionalPatientInfo";
import UserInfoRow from "./UserInfoRow";

const messages: Record<number, string> = {
  0: "",
  1: "Consultation for medical review",
  2: "Consultation by request (not vetted)",
  3: "PT Consultation-member initiated",
};

const VisitCause = ({
  additionalInfo,
  booking,
  currentJoint,
}: {
  additionalInfo: AdditionalPatientInfoProps["additionalInfo"];
  booking: AdditionalPatientInfoProps["booking"];
  currentJoint: UserJoint | undefined;
}) => {
  const { t } = useTranslation();
  const [cause, setCause] = useState<number>(0);

  useEffect(() => {
    if (additionalInfo?.status === "success") {
      switch (true) {
        case booking.eventType.length === 40 &&
          currentJoint &&
          currentJoint.status === "vettedout_scheduledreview":
          setCause(1);
          break;
        case booking.eventType.length === 40 &&
          currentJoint &&
          currentJoint.status !== "vettedout_scheduledreview":
          setCause(2);
          break;
        case booking.eventType.length === 30:
          setCause(3);
          break;
      }
    } else {
      setCause(0);
    }
  }, [booking, currentJoint, additionalInfo]);

  return <UserInfoRow label={t("member_visit_cause")} value={messages[cause]} />;
};

export default VisitCause;
