import { getUserAnswersColor } from "@stss/configs";
import type { UserAnswersResponse, SurveyAnswer, AnswersKey, UserJoint } from "@stss/types";
import { useTranslation } from "next-i18next";
import { useState } from "react";

type UserAnswersProps = {
  loadAnswers: () => void;
  answers: null | UserAnswersResponse;
  label: string;
  currentJoint: UserJoint | undefined;
};

function UserAnswers({ loadAnswers, answers, label, currentJoint }: UserAnswersProps) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onclick = () => {
    if (!answers) {
      loadAnswers();
    } else {
      setCollapsed(!collapsed);
    }
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <p className="text-blue-500 underline">
        <span className="cursor-pointer" onClick={onclick}>
          {!answers ? `${t("load")} ${label}` : label}
        </span>
      </p>
      {answers && !collapsed && (
        <div>
          <div className="mt-2 pl-2">
            {currentJoint ? (
              <div className="inline rounded border border-green-600 bg-green-600 px-2 py-[1px]">
                <span
                  className="mr-1 font-bold"
                  title={`${t("current")} ${t("joint")} (ID ${currentJoint.id})`}>
                  {t("joint")}:
                </span>
                <span title={currentJoint.description} className="mr-1">
                  {currentJoint.name}
                </span>
                <span title={t("joint") + " " + t("status")}>
                  ({t("status").toLowerCase() + " - " + currentJoint.statusDisplayName})
                </span>
              </div>
            ) : (
              <div className="inline rounded border border-orange-600 bg-orange-600 px-2 py-[1px]">
                <span>{`${t("member_initiated_visit")}. ${t("no_current_program")}.`}</span>
              </div>
            )}
          </div>
          {Object.keys(answers).map((key: string) => {
            const arr = answers[key];
            const color = getUserAnswersColor(key as AnswersKey);
            const surveyID = Array.isArray(arr) ? arr[0]?.survey_id : null;
            return (
              <div className="mt-2 pl-2" key={key}>
                {Array.isArray(arr) && arr.length > 0 ? (
                  <>
                    <p className={`text-${color}-500`}>
                      <span className="font-medium capitalize">{key.replaceAll("_", " ")}</span>
                      {surveyID && <span className="ml-2">{`(${t("survey")} ID - ${surveyID}):`}</span>}
                    </p>
                    <ul className="mt-1">
                      {answers[key].map((answer: SurveyAnswer, i: number) => (
                        <li key={i}>
                          <div
                            className={`mb-1 flex flex-wrap rounded border-l-2 text-${color}-900 border-${color}-800 bg-${color}-100 px-2 py-1 leading-none`}>
                            <p className="flex w-full pr-2 xl:w-1/2">
                              <span className="mr-2">{t("question_short")}:</span>
                              <span>{answer.question}</span>
                            </p>
                            <p className="flex w-full xl:w-1/2">
                              <span className="mr-2">{t("answer_short")}:</span>
                              <span>{answer.answer}</span>
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <div className="mt-1 text-gray-700">{/* <p>{t("no_answers")}</p> */}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default UserAnswers;
