export const BookingInfoGraphic = () => {
  return (
    <div>
      <div className="hidden text-center md:block">
        <img
          src="/st/img/book_infographic.jpg"
          alt="Booking infographic"
          className="inline-block max-w-full shadow-[5px_5px_20px_0_rgba(0,0,0,0.16)]"
        />
      </div>
      <div className="block text-center md:hidden">
        <img
          src="/st/img/book_infographic_details.jpg"
          alt="Booking infographic"
          className="mb-8 inline-block w-52 max-w-full shadow-[5px_5px_20px_0_rgba(0,0,0,0.16)] sm:mb-12 sm:w-60"
        />
        <img
          src="/st/img/book_infographic_calendar.jpg"
          alt="Booking infographic"
          className="mb-8 inline-block max-w-full shadow-[5px_5px_20px_0_rgba(0,0,0,0.16)] sm:mb-12"
        />
        <img
          src="/st/img/book_infographic_hours.jpg"
          alt="Booking infographic"
          className="inline-block w-52 max-w-full shadow-[5px_5px_20px_0_rgba(0,0,0,0.16)] sm:w-60"
        />
      </div>
    </div>
  );
};
