import classNames from "classnames";

import { STStatus } from "@calcom/prisma/client";

function statusClassName(status: STStatus) {
  switch (status) {
    case STStatus.SCHEDULED:
      return "bg-blue-100 text-blue-800 border-blue-800";
    case STStatus.RESCHEDULED:
      return "bg-blue-100 text-blue-800 border-blue-800";
    case STStatus.CANCELLED:
      return "bg-red-100 text-red-800 border-red-800";
    case STStatus.MISSED:
      return "bg-orange-100 text-orange-800 border-orange-800";
    case STStatus.COMPLETED:
      return "bg-green-100 text-green-800 border-green-800";
    default:
      return "";
  }
}

function STBookingStatus({ status }: { status: STStatus }) {
  if (!status) return null;
  return (
    <span className={classNames("rounded border px-2 py-[2px] text-sm", statusClassName(status))}>
      {status.toLowerCase()}
    </span>
  );
}

export default STBookingStatus;
