import type {
  VisitDetailsUser,
  VideoVisit,
  STUser,
  UserDetailsDataPayload,
  UserJoint,
  CompanyConfigs,
  STClientLoginResponse,
  UpdateVisitDetailsPayload,
  UserAnswersPayload,
  UserAnswersResponse,
  STClientAPIAccess,
  GetUserPrefLanguagePayload,
  SupportedLanguage,
  SupportedLanguagesResponse,
} from "@stss/types";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError, RawAxiosRequestHeaders, AxiosResponse } from "axios";
import CryptoJS from "crypto-js";
import type WordArray from "crypto-js/lib-typedarrays";

import dayjs from "@calcom/dayjs";

const CDN_BASE_URL = process.env.NEXT_PUBLIC_ST_CDN_BASE_URL || "";
const ST_USER_API_KEY = process.env.NEXT_PUBLIC_ST_USER_API_KEY || "";
const ST_USER_API_URL =
  process.env.NEXT_PUBLIC_ST_USER_API_URL || "https://hkibxp8wji.execute-api.us-west-2.amazonaws.com";
const ST_BASE_CONFIGS_URL =
  process.env.NEXT_PUBLIC_ST_BASE_CONFIGS_URL || "https://hwsckk95wl.execute-api.us-west-2.amazonaws.com";
const baseConfigsAPIHeaders = {
  "Content-Type": "application/json; charset=UTF-8",
  "x-api-key": process.env.NEXT_PUBLIC_ST_BASE_CONFIGS_API_KEY || "",
};

const ST_API_URL = process.env.NEXT_PUBLIC_ST_API_URL;
const ST_CLIENT_APP_ID = process.env.NEXT_PUBLIC_ST_CLIENT_APP_ID;
const ST_CLIENT_APP_HASH = process.env.NEXT_PUBLIC_ST_CLIENT_APP_HASH;

// this function is used for calculating additional visit details
const getVisitLocalDetails = (visit: VideoVisit) => {
  const durationUnits = "minutes";
  const utcStartDate = dayjs.utc(visit.scheduled_start_dt);
  const utcEndDate = dayjs.utc(visit.scheduled_end_dt);
  const visitLocalStartDate = dayjs(utcStartDate).local();
  const visitLocalEndDate = dayjs(utcEndDate).local();
  const duration = visitLocalEndDate.diff(visitLocalStartDate, durationUnits);
  const dayNumber = visitLocalStartDate.format("DD");
  const dayName = visitLocalStartDate.format("dddd");
  const monthName = visitLocalStartDate.format("MMMM");
  const date = visitLocalStartDate.format("DD MMM YYYY");
  const startTime = visitLocalStartDate.format("h:mma");
  const endTime = visitLocalEndDate.format("h:mma");

  return {
    localStartDate: visitLocalStartDate.format("YYYY-MM-DD HH:mm:ss"),
    localEndDate: visitLocalEndDate.format("YYYY-MM-DD HH:mm:ss"),
    visitDate: visitLocalStartDate.format("MM-DD-YYYY"),
    durationInt: duration,
    durationStr: duration + " " + durationUnits,
    durationUnits: durationUnits,
    dayNumber: dayNumber,
    dayName: dayName,
    monthName: monthName,
    date: date,
    shortDate: visitLocalStartDate.format("M/D/YY"),
    startTime: startTime,
    endTime: endTime,
    timeString: `${startTime} - ${endTime}`,
    dateString: `${dayName}, ${date}`,
    fullString: `${dayName}, ${date}, ${startTime} - ${endTime}`,
    missed: visit.scheduled_status === "missed",
    cancelled: visit.scheduled_status === "cancelled",
  };
};

function getUrlencodedForm(data: { [key: string]: string | number }): string {
  const formBody: unknown[] = [];
  for (const property in data) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

function getHeaders(): RawAxiosRequestHeaders {
  const headers: RawAxiosRequestHeaders = {
    "Accept-Language": "en",
  };
  if (ST_CLIENT_APP_ID) {
    headers["Client-App-Id"] = ST_CLIENT_APP_ID;
  }
  if (ST_CLIENT_APP_HASH) {
    headers["Client-App-Hash"] = ST_CLIENT_APP_HASH;
    // headers["Client-App-Hash"] = "b1ed9df7de08cddf7c2389c741804762870179e0";
  }
  return headers;
}

export function createAccess(configs: STClientLoginResponse): STClientAPIAccess | null {
  const { clientApiUrl, clientAppSettings } = configs;
  if (!clientApiUrl || !clientAppSettings) return null;
  const settings = clientAppSettings?.WEBAPP;

  const sha1 = (data: string | WordArray) => {
    return CryptoJS.SHA1(data).toString(CryptoJS.enc.Hex);
  };

  const hash = (clientAppId: string, clientAppKey: string, salt: string) => {
    return sha1(clientAppId + clientAppKey.replace(/\\/g, "") + salt);
  };

  return {
    url: clientApiUrl,
    headers: {
      "Content-Type": "application/json",
      "Client-App-Id": settings.clientAppId,
      "Client-App-Hash": hash(settings.clientAppId, settings.clientAppKey, settings.salt),
    },
  };
}

export async function getVisitDetails(visitParams: string | string[] | undefined, user: VisitDetailsUser) {
  return new Promise<VideoVisit>((resolve, reject) => {
    if (!visitParams) {
      reject(new Error("Missed required parameter visitID in getVisitDetails method"));
    }
    const visitID = Array.isArray(visitParams) ? visitParams[0] : visitParams;
    const data = {
      id: user?.id,
      username: user?.email,
      role: "provider",
    };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: ST_API_URL + "/api/stss/visits/" + visitID,
      headers: getHeaders(),
      data,
      params: {
        include_room: true,
      },
    };
    axios(config)
      .then((response) => {
        const visit = response?.data?.visit;
        if (!visit) {
          reject(new Error("Visit details not found"));
        }
        resolve({
          ...visit,
          localDetails: getVisitLocalDetails(visit),
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getUserDetails(data: UserDetailsDataPayload): Promise<STUser> {
  const payload = { ...data, role: data.role || "provider" };
  const config: AxiosRequestConfig = {
    method: "POST",
    url: ST_API_URL + "/api/user/getUserDetails",
    headers: getHeaders(),
    data: payload,
  };
  return new Promise<STUser>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function getUserDetailsThroughLogin(data: UserDetailsDataPayload): Promise<STUser> {
  const { stUserEmail } = data;
  if (!stUserEmail) {
    throw new Error("Missed required parameter stUserEmail in getUserDetailsThroughLogin method");
  }
  const loginResponse = await loginSTUser(stUserEmail);
  if (loginResponse.result && loginResponse.clientApiUrl) {
    const access = createAccess(loginResponse);
    const payload = {
      id: data.id,
      username: data.username,
      stUserId: data.stUserId,
      role: data.role || "provider",
    };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: access?.url + "/api/user/getUserDetails",
      headers: access?.headers,
      data: payload,
    };
    return new Promise<STUser>((resolve, reject) => {
      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  } else {
    throw new Error(`Login "${stUserEmail}" error!`);
  }
}

export function fetchUserSurveyAnswers(payload: UserAnswersPayload): Promise<UserAnswersResponse> {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: ST_API_URL + "/api/user/surveyAnswers",
    headers: getHeaders(),
    data: { ...payload, role: "provider" },
  };
  return new Promise<UserAnswersResponse>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getUsersDetails(data: UserDetailsDataPayload): Promise<unknown>[] | Promise<unknown> {
  if (Array.isArray(data.stUserId)) {
    const promises: Promise<unknown>[] = [];
    data.stUserId.forEach((id) => promises.push(getUserDetails({ ...data, stUserId: id })));
    return Promise.all(promises);
  } else {
    return getUserDetails(data);
  }
}

export function getAllJoints(): Promise<UserJoint[]> {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: ST_API_URL + "/api/locale/joints",
    headers: {
      ...getHeaders(),
      // "Client-App-Id": "ST_IOS",
    },
  };
  return new Promise<UserJoint[]>((resolve, reject) => {
    axios(config)
      .then((response) => {
        const joints =
          response.data.joints && Array.isArray(response.data.joints) ? response.data.joints : response.data;
        resolve(joints.map((el: UserJoint) => ({ ...el, id: +el.id })));
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type STUserResponse = {
  stUserId: null | number;
  errorMessage: string;
};

export function getSTUserByEmail(email: string, access: STClientAPIAccess): Promise<{ user_id: number }> {
  if (!access) {
    throw new Error("Missed required parameter access in getSTUserByEmail method");
  }
  const { url, headers } = access;
  const config: AxiosRequestConfig = {
    method: "POST",
    url: url + "/api/user/getUserIdByEmail",
    headers: headers,
    data: {
      email,
    },
  };
  return new Promise<{ user_id: number }>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e: AxiosError) => {
        reject(e);
      });
  });
}

export async function validateSTUserByEmail(
  email: string,
  access: STClientAPIAccess
): Promise<STUserResponse> {
  const responseData: STUserResponse = {
    stUserId: null,
    errorMessage: "Please enter the email that you used during registration_",
  };
  try {
    const stUserResponse: { user_id: number } = await getSTUserByEmail(email, access);
    responseData.stUserId = stUserResponse.user_id;
    return responseData;
  } catch (e: unknown) {
    const error = e as AxiosError;
    const errorData: AxiosResponse["data"] = error.response?.data;
    if (errorData) {
      const responseMessage = errorData.error ? errorData.error : errorData.message;
      if (responseMessage && typeof responseMessage === "string") {
        responseData.errorMessage = responseMessage;
      }
    } else {
      responseData.errorMessage = "User validation error.";
    }
    return responseData;
  }
}

export async function updateVisitDetails(
  apiUrl: string,
  payload: UpdateVisitDetailsPayload
): Promise<{ success: boolean }> {
  const config: AxiosRequestConfig = {
    method: "POST",
    headers: getHeaders(),
    data: payload,
    url: apiUrl + "/api/stss/updateVisitDetails",
  };
  return axios(config)
    .then((res) => res.data)
    .catch((err) => err);
}

export function getAllCompanies() {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: ST_BASE_CONFIGS_URL + "/Development/clients/",
    headers: baseConfigsAPIHeaders,
  };
  return new Promise<{ arrayOfCompanies: unknown[] }>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e: AxiosError) => {
        reject(e);
      });
  });
}

export async function getCompanyConfigsByID(companyID: string) {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: ST_BASE_CONFIGS_URL + `/Development/clients/${companyID}/configurations`,
    headers: baseConfigsAPIHeaders,
  };
  return new Promise<CompanyConfigs>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e: AxiosError) => {
        reject(e);
      });
  });
}

export async function loginSTUser(email: string) {
  const payload = getUrlencodedForm({
    email: email,
  });
  const config: AxiosRequestConfig = {
    method: "POST",
    url: ST_USER_API_URL + "/prod/login",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": "en",
      "x-api-key": ST_USER_API_KEY,
    },
    data: payload,
  };
  return new Promise<STClientLoginResponse>((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e: AxiosError) => {
        reject(e);
      });
  });
}

export async function getUserPrefLanguage(payload: GetUserPrefLanguagePayload, access: STClientAPIAccess) {
  if (!access) {
    throw new Error("Missed required parameter access in getUserPrefLanguage method");
  }
  const { url, headers } = access;
  const config: AxiosRequestConfig = {
    method: "POST",
    headers: headers,
    data: payload,
    url: url + "/api/user/getUserPrefLang",
  };
  return axios(config)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getAllowedLanguages() {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: CDN_BASE_URL + "/locale/supported_languages_v3.json",
  };
  return new Promise<SupportedLanguagesResponse>((resolve, reject) => {
    axios(config)
      .then((response) => {
        const toReturn: SupportedLanguagesResponse = {
          ui: [],
          captions: [],
          lpt: [],
        };
        const languages: SupportedLanguage[] = response.data;
        if (languages.some((lang) => Array.isArray(lang.using))) {
          languages.forEach((lang) => {
            if (Array.isArray(lang.using)) {
              lang.using.forEach((using) => {
                if (using === "ui") {
                  toReturn.ui.push(lang);
                } else if (using === "captions") {
                  toReturn.captions.push(lang);
                } else if (using === "lpt") {
                  toReturn.lpt.push(lang);
                }
              });
            }
          });
        } else {
          toReturn.ui = languages;
          toReturn.captions = languages;
          toReturn.lpt = languages;
        }
        resolve(toReturn);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
