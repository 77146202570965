export const Heading = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <h1
      className={
        "font-av sm:text-d5xl text-3xl font-extrabold leading-tight sm:leading-snug lg:text-6xl lg:leading-snug " +
        className
      }>
      {children}
    </h1>
  ) : null;
};
