import classNames from "classnames";
import React from "react";

// import { ArrowLeftIcon } from "@calcom/ui/components/icon";

export type STError = {
  message: string;
};

type ErrorsProps = {
  errors: STError[];
  action: () => void;
  timeLeft: number;
  buttonText?: string;
  errorsText?: string;
};

function Errors({ errors, action, timeLeft, buttonText, errorsText }: ErrorsProps) {
  if (!errors.length) {
    return null;
  }
  return (
    <div>
      <h3 className="text-lg font-bold text-orange-900">{errorsText || "Errors"}:</h3>
      <ul className="list-disc pl-7">
        {errors.map((error: STError) => (
          <li key={error.message}>{error.message};</li>
        ))}
      </ul>
      <div className="py-4">
        <button
          className={classNames(
            "flex items-center items-center justify-center rounded border-0 px-4 py-2",
            timeLeft
              ? "bg-gray-200 text-gray-900 hover:bg-gray-300"
              : "bg-gray-700 text-gray-200 hover:bg-gray-900"
          )}
          onClick={action}>
          {/* <ArrowLeftIcon width={15} height={15} className="mr-3 inline-block" /> */}
          <span>{buttonText || "Go back"}</span>
          {timeLeft ? <span className="ml-2">{`(${timeLeft})`}</span> : null}
        </button>
      </div>
    </div>
  );
}

export default Errors;
