import type { UserJoint } from "@stss/types";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useState } from "react";

const UserJoints = ({ label, joints }: { label: string; joints: UserJoint[] }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const getLabel = () => {
    if (joints.length > 0) {
      return (
        <p className="text-blue-500 underline">
          <span className="cursor-pointer" onClick={() => setCollapsed(!collapsed)}>
            {label + " (" + joints.length + ")"}
          </span>
        </p>
      );
    } else {
      return <p className="cursor-pointer text-gray-700">{"No " + label.toLowerCase()}</p>;
    }
  };

  return (
    <div className="mb-1 p-0 text-sm" onClick={(event) => event.stopPropagation()}>
      {getLabel()}
      <div className="py-0 pl-2">
        {!collapsed && joints.length && (
          <ul className={classNames("mt-1 overflow-hidden", collapsed && "h-0")}>
            {joints.map((joint: UserJoint) => (
              <li key={joint.id} title={joint.description}>
                <p
                  className={classNames(
                    "mb-1 overflow-hidden whitespace-nowrap  rounded border-l-2 px-2",
                    joint.current
                      ? "border-green-800 bg-green-100 text-green-900"
                      : "border-blue-800 bg-blue-100 text-blue-900"
                  )}>
                  <span className={classNames(joint.current ? "font-medium" : "")}>{joint.display_name}</span>
                  {joint.status && (
                    <span className="ml-1 text-gray-500">
                      {`(${t("status").toLowerCase()} - ${joint.statusDisplayName})`}
                    </span>
                  )}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserJoints;
