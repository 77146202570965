import { useTranslation } from "next-i18next";

export function VisitName({ visitLength }: { visitLength: number }) {
  const { t } = useTranslation();

  const getTranslation = (length: number) => {
    const key = "visit_length_" + length;
    return t(key) !== key ? t(key) : t("visit_length_fallback", { length: length });
  };

  return <span className="custom-visit-name">{getTranslation(visitLength)}</span>;
}
