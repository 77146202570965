import { SocialLinks } from "@stss/ui";
import { Logo } from "@stss/ui";
import Link from "next/link";
import React from "react";
import { useRef } from "react";

import { SlideOutBanner } from "../components/SlideOutBanner";

export type HeaderProps = {
  logoLink: string;
  logoTitle: string;
  ctaLink: string;
  ctaTitle: string;
};

export const Header = ({ logoLink, logoTitle, ctaTitle, ctaLink }: HeaderProps) => {
  const menuWrapper = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement | null>(null);

  const toggleMenu = () => {
    menuWrapper.current?.classList.toggle("hidden");
  };

  return (
    <>
      <header
        ref={headerRef}
        className="sticky top-0 z-50 bg-white px-4 py-4 md:px-6 md:py-6 lg:px-7 lg:py-7">
        <div className="container mx-auto">
          <div className="flex columns-1 items-center justify-between md:columns-2">
            <div className="flex flex-auto items-center">
              <button className="mr-5 md:hidden" title="Menu" onClick={toggleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="var(--brand-blue)"
                  className="h-6 w-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                  />
                </svg>
              </button>
              <div className="flex flex-auto items-center justify-center md:justify-start">
                <Logo size="middle" logoLink={logoLink} logoTitle={logoTitle} />
              </div>
            </div>
            <div className="font-av hidden justify-self-end font-extrabold md:block">
              <Link
                href={ctaLink}
                title={ctaTitle}
                className="border-stprimary-orange-900 text-stprimary-orange-900 hover:bg-stprimary-orange-900 inline-block rounded border-2 px-8 py-1 hover:text-white">
                {ctaTitle}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div
        ref={menuWrapper}
        className="fixed left-0 top-0 z-50 hidden h-full w-full bg-gray-900/[0.5]"
        onClick={toggleMenu}>
        <div
          className="bg-stBackground-light h-full w-[300px] max-w-full p-5 sm:w-96"
          onClick={(e) => e.stopPropagation()}>
          <div className="flex items-center justify-between">
            <Logo size="middle" logoLink={logoLink} logoTitle={logoTitle} />
            <button onClick={toggleMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="font-av mt-16 font-bold">
            <Link
              href={ctaLink}
              title={ctaTitle}
              className="border-stprimary-orange-900 text-stBackground-light bg-stprimary-orange-900 inline-block w-full rounded border-2 px-3 py-1 text-center text-[13px] sm:text-lg">
              {ctaTitle}
            </Link>
          </div>
          <div className="mt-12 text-center">
            <SocialLinks />
          </div>
        </div>
      </div>
      <SlideOutBanner position="top" headerRef={headerRef}>
        <div className="font-av container">
          <div className="mb-1">
            <h2 className="text-md mb-1 font-bold">
              Attention BH and In-Person Providers: Provider Page Under Construction
            </h2>
            <p className="mt-1 text-xs">
              Our provider page is currently undergoing improvements to serve you better. During this time:
            </p>
          </div>
          <div className="text-left">
            <ul>
              <li className="text-xs">
                <span className="font-bold">
                  For in-person PTs, OTs, STs, Acupuncturists, and Chiropractors:
                </span>{" "}
                <span className="mr-1">Please visit</span>
                <a
                  href="https://physmetrics.com/providers/"
                  className="text-white underline transition-all duration-300 hover:text-[#0097CD]">
                  https://physmetrics.com/providers/
                </a>
                to log in or print forms.
              </li>
              <li className="text-xs">
                <span className="font-bold">For Behavioral Health providers:</span>{" "}
                <span className="mr-1">Please visit</span>
                <a
                  href="https://halcyonbehavioral.com/providers/"
                  className="text-white underline transition-all duration-300 hover:text-[#0097CD]">
                  https://halcyonbehavioral.com/providers/
                </a>
                to log in or print forms.
              </li>
            </ul>
            <p className="text-xs">We appreciate your patience as we work on enhancing your experience.</p>
          </div>
        </div>
      </SlideOutBanner>
    </>
  );
};
