import { useTranslation } from "next-i18next";
import React from "react";

import type { ActionType } from "@calcom/ui";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
} from "@calcom/ui";
import { Video, MoreHorizontal } from "@calcom/ui/components/icon";
import type { BookingItemProps } from "@calcom/web/components/booking/BookingListItem";

type BookingItemActionsInfoProps = {
  booking: BookingItemProps;
  menuItems: ActionType[];
};

function BookingItemActions({ booking, menuItems }: BookingItemActionsInfoProps) {
  const { t } = useTranslation();
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <ButtonGroup combined>
        <Tooltip content={t("visit")}>
          <Button color="secondary" variant="icon" href={`/visit/${booking.id}`} StartIcon={Video} />
        </Tooltip>
        <Dropdown modal={false}>
          <DropdownMenuTrigger asChild className="radix-state-open:rounded-r-md">
            <Button type="button" variant="icon" color="secondary" StartIcon={MoreHorizontal} />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {menuItems.map((item) => (
              <DropdownMenuItem key={item.id}>
                <DropdownItem type="button" StartIcon={item.icon} onClick={item.onClick}>
                  {item.label}
                </DropdownItem>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </Dropdown>
      </ButtonGroup>
    </span>
  );
}

export default BookingItemActions;
