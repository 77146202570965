import React from "react";

export type SvgSeparatorProps = {
  fill?: string;
  className?: string;
};

export const SvgSeparator = ({ fill, className }: SvgSeparatorProps) => {
  const pathFill = fill || "#EBFAFF";
  return (
    <div className={"my-[-1px] h-[10vw] w-full" + " " + className}>
      <div className="relative h-full w-full">
        <svg
          className="absolute left-0 top-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="1523 -97.8 1749.6 199.5">
          <path
            fill={pathFill}
            d="M2348.4-10.7c-617,230.9-816,37.8-825.4,28.3v84.7h1750.2V73.4C3273.2,73.4,2980.8-247.4,2348.4-10.7z"
          />
        </svg>
      </div>
    </div>
  );
};
