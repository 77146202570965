import { useTranslation, Trans } from "next-i18next";
import React from "react";

const Translate = ({ i18nKey, values }: { i18nKey: string; values?: Record<string, string | number> }) => {
  const { t } = useTranslation();
  return (
    <Trans
      i18nKey={i18nKey}
      values={{
        companyName: t("st_brand_name"),
        ...values,
      }}
      components={{
        strong: <b className="font-bold" />,
        br: <br />,
      }}
    />
  );
};

export default Translate;
