export const SubscriptionForm = ({
  placeholder,
  buttonText,
}: {
  placeholder: string;
  buttonText: string;
}) => {
  return (
    <form action="#">
      <input
        type="email"
        className="border-1 text-stprimary-gray placeholder-stprimary-gray m-1 rounded px-4 py-2"
        placeholder={placeholder}
        required
      />
      <button
        type="submit"
        className="font-av border-stprimary-orange-900 bg-stprimary-orange-900 hover:stprimary-orange-900 hover:text-stprimary-orange-900 border-stprimary-orange-900 m-1 rounded border px-4 py-2 font-bold text-white hover:bg-white">
        {buttonText}
      </button>
    </form>
  );
};
