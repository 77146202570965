import { Translate, SubHeading, CardHeading, CardText } from "@stss/ui";

export type WhatYouDoItem = {
  i18keyTitle: string;
  i18keySubTitle: string;
  icon: string;
};

export const WhatYouDo = ({ items }: { items: WhatYouDoItem[] }) => {
  const iconPath = "/st/img/icons/";

  return (
    <div>
      <SubHeading className="mb-4 text-center md:mb-8 lg:mb-12">
        <Translate i18nKey="what_you_do_title" />
      </SubHeading>
      <div className="m-auto flex flex-col items-center xl:flex-row" style={{ maxWidth: "1280px" }}>
        {items.map((item, i) => (
          <div
            className="aspect-square h-80 w-80 p-6 sm:h-[350px] sm:w-[350px] lg:h-[450px] lg:w-[450px] xl:h-full xl:w-1/3 xl:w-full"
            key={i}>
            <div className="bg-stBackground-light flex h-full w-full flex-col items-center justify-center p-3 shadow-[5px_5px_20px_0_rgba(0,0,0,0.16)]">
              <div className="flex h-[40%] items-center">
                <img
                  src={iconPath + item.icon}
                  alt={item.i18keyTitle}
                  className="max-h-[60px] shrink-0 lg:max-h-[90px]"
                />
              </div>
              <div className="flex h-[20%] items-center">
                <CardHeading>
                  <Translate i18nKey={item.i18keyTitle} />
                </CardHeading>
              </div>
              <div className="h-[40%]">
                <CardText className="lg:text-[18px]">
                  <Translate i18nKey={item.i18keySubTitle} />
                </CardText>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
