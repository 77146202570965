import type { VisitStatus, JointStatus } from "@stss/types";

import { STStatus } from "@calcom/prisma/client";

export const visitStatusTranslations: Record<VisitStatus, string> = {
  missed: "visit_status_missed",
  Booked: "visit_status_booked",
  completed: "visit_status_completed",
  medically_cleared: "visit_status_medically_cleared",
  rescheduled: "visit_status_rescheduled",
  cancelled: "visit_status_cancelled",
  unqualified: "visit_status_unqualified",
  allow_pt: "visit_status_allow_pt",
};

export const jointStatusTranslations: Record<JointStatus, string> = {
  enrolled: "joint_status_enrolled",
  onboarded: "joint_status_onboarded",
  onboarded_medicallycleared: "joint_status_onboarded_medicallycleared",
  vettedout_pendingreview: "joint_status_vettedout_pendingreview",
  vettedout_scheduledreview: "joint_status_vettedout_scheduledreview",
  vettedout_unqualified: "joint_status_vettedout_unqualified",
};

export const statusesComparing: { [key in VisitStatus]: STStatus } = {
  Booked: STStatus.SCHEDULED,
  rescheduled: STStatus.RESCHEDULED,
  medically_cleared: STStatus.COMPLETED,
  unqualified: STStatus.COMPLETED,
  completed: STStatus.COMPLETED,
  cancelled: STStatus.CANCELLED,
  missed: STStatus.MISSED,
  allow_pt: STStatus.COMPLETED,
};

export const SAVED_VIDEO_FILTER_KEY = "stss.saved-video-filter";
