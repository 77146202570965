import classNames from "classnames";
import Link from "next/link";
import React from "react";

export type LogoSize = "small" | "middle" | "large";

export type LogoProps = {
  logoLink: string;
  logoTitle: string;
  size: LogoSize;
};

export const Logo = ({ logoLink, logoTitle, size }: LogoProps) => {
  const sizeClassNames: { [key in LogoSize]: string } = {
    small: "w-[100px] md:w-[150px]",
    middle: "w-[150px] md:w-[250px]",
    large: "w-[250px] md:w-[350px]",
  };

  const sizeClass = size ? sizeClassNames[size] : sizeClassNames.middle;

  return (
    <Link
      href={logoLink}
      className={classNames("inline-block max-w-full", sizeClass)}
      target="_blank"
      rel="noreferrer"
      title={logoTitle}>
      <img src="/st/img/logo/st-logo.svg" alt="SimpleTherapy" className="max-w-full" />
    </Link>
  );
};
