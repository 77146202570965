import classNames from "classnames";
import React from "react";

type FilledBackgroundCircleProps = React.AllHTMLAttributes<HTMLElement>;

function FilledBackgroundCircle({ className, ...rest }: FilledBackgroundCircleProps) {
  return (
    <div
      {...rest}
      className={classNames(
        "bg-stBackground-lightBlue dark:bg-muted absolute bottom-0 left-1/2 -z-10 h-[800vw] w-[800vw] -translate-x-1/2 rounded-full border-red-100",
        className
      )}
    />
  );
}

export default FilledBackgroundCircle;
