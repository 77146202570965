import classNames from "classnames";
import React from "react";

type FullScreenLoaderProps = {
  spinnerSize?: number;
  spinnerColor?: string;
  overLayBackground?: string;
};

function FullScreenLoader({ spinnerSize, spinnerColor, overLayBackground }: FullScreenLoaderProps) {
  const spinnerSizeClass = spinnerSize ? `h-${spinnerSize} w-${spinnerSize}` : "h-10 w-10";
  const spinnerColorClass = spinnerColor ? `text-${spinnerColor}` : "text-black";
  const overLayBackgroundClass = overLayBackground ? `bg-${overLayBackground}` : "bg-gray-300";
  return (
    <div className="absolute left-0 top-0 z-10 h-full w-full cursor-not-allowed" title="Loading...">
      <div
        className={classNames("absolute left-0 top-0 z-10 h-full w-full opacity-5", overLayBackgroundClass)}
      />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <svg
          className={classNames("mx-4 animate-spin", spinnerSizeClass, spinnerColorClass)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    </div>
  );
}

export default FullScreenLoader;
