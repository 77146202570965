import { Trans } from "next-i18next";

import { useLocale } from "@calcom/lib/hooks/useLocale";

const companyPhone = "18006442478";
const humanReadableCompanyPhone = "1-800-644-2478";
const stWebsiteUrl = process.env.NEXT_PUBLIC_ST_WEBSITE_URL || "https://www.simpletherapy.com/";

export function STCompanyInfo() {
  const { t } = useLocale();
  return (
    <>
      <h3 className="py-3 text-lg font-bold text-neutral-900">
        {t("st_brand_name") + " " + t("scheduling_service")}
      </h3>
      <div className="text-sm">
        <Trans
          className="text-sm"
          i18nKey="st_company_info"
          values={{
            brand_name: t("st_brand_name"),
            company_phone: humanReadableCompanyPhone,
          }}
          components={{
            phone_link: <a className="hover:text-blue-500 hover:underline" href={"tel:" + companyPhone} />,
          }}
        />
        <p className="mt-6">{t("st_brand_name") + " Inc."}</p>
        <p>{t("st_address")}</p>
        <p>
          <a
            href={stWebsiteUrl}
            className="hover:text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer">
            {t("st_web_site_name")}
          </a>
        </p>
      </div>
    </>
  );
}
