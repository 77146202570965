import type { ReactNode } from "react";
import { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useCountUp } from "use-count-up";

export type CircularProgressCardProps = {
  iconSrc: string;
  progressValue: number;
  animationDuration?: number;
  textNode?: ReactNode;
};

export const CircularProgressCard = ({
  progressValue,
  iconSrc,
  textNode,
  animationDuration,
}: CircularProgressCardProps) => {
  const duration = animationDuration || 1.5;
  const circle = useRef<HTMLDivElement>(null);
  const [startCounting, setStartCounting] = useState<boolean>(false);

  const { value } = useCountUp({
    isCounting: startCounting,
    start: 0,
    end: progressValue,
    duration,
  });

  const onChange = (isVisible: boolean) => {
    if (isVisible && !startCounting) {
      if (circle.current) {
        circle.current.classList.add("parent-animated");
      }
      setStartCounting(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange}>
      <div className="font-av flex flex-col items-center justify-center">
        <div ref={circle} className="circular-chart mb-2 w-40 md:mb-4 md:w-52 lg:w-60">
          <svg viewBox="0 0 36 36">
            <path
              className="circle-bg"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle-fill"
              style={{ animationDuration: duration + "s" }}
              strokeDasharray={progressValue + ", 100"}
              stroke="url(#brandGradient)"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <img className="icon" src={iconSrc} alt="Improvement" />
        </div>
        <p className="mb-1 text-center text-3xl font-extrabold md:mb-2 md:text-4xl lg:text-5xl">{value}</p>
        {textNode && <p className="text-center text-[22px] leading-7 md:text-2xl">{textNode}</p>}
      </div>
    </VisibilitySensor>
  );
};
