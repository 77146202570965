export const UpperHeading = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <h3
      className={
        "font-av text-lg font-semibold leading-6 md:text-[30px] md:leading-[46px] lg:text-[40px] lg:leading-[55px]" +
        " " +
        className
      }>
      {children}
    </h3>
  ) : null;
};
