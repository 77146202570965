export const Paragraph = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <p
      className={
        "font-av text-lg font-normal leading-[22px] md:text-2xl md:leading-8 lg:text-[28px] lg:leading-[40px]" +
        " " +
        className
      }>
      {children}
    </p>
  ) : null;
};
