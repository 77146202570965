import classNames from "classnames";
import React from "react";

import classes from "./button.module.scss";

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  color?: "primary" | "blue" | "green";
  size?: "small" | "medium" | "large" | "xl" | "xxl";
  stroked?: boolean;
  rounded?: boolean;
  uncontained?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  children,
  className,
  color = "primary",
  size = "medium",
  stroked,
  rounded,
  uncontained,
  isLoading = false,
  type = "button",
  ...rest
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames(
        classes.button,
        isLoading && classes.loading,
        color === "primary" && classes.primary,
        color === "green" && classes.green,
        color === "blue" && classes.blue,
        size === "small" && classes.small,
        size === "medium" && classes.medium,
        size === "large" && classes.large,
        size === "xl" && classes.xl,
        size === "xxl" && classes.xxl,
        uncontained && classes.uncontained,
        stroked && classes.stroked,
        rounded && classes.rounded,
        className
      )}
      {...rest}>
      <span className={classNames(classes.children)}>{children}</span>
    </button>
  );
}
