import { Heading, UpperHeading, Paragraph } from "@stss/ui/src/layout/typography";

export type PageHeadProps = {
  upperTitleNode?: React.ReactNode;
  titleNode: React.ReactNode;
  subtitleNode?: React.ReactNode;
  callToActionNode?: React.ReactNode;
  imageSrc: string;
  imagePseudoSchema: "schema-1" | "schema-2";
};

export const PageHead = ({
  upperTitleNode,
  titleNode,
  subtitleNode,
  callToActionNode,
  imageSrc,
  imagePseudoSchema,
}: PageHeadProps) => {
  return (
    <div className="flex columns-1 flex-col items-center py-3 md:columns-2 md:flex-row md:py-7 lg:py-10">
      <div className="relative z-10 order-2 w-full px-5 py-4 md:order-1 md:w-1/2">
        <UpperHeading className="mb-3 text-center md:text-left">{upperTitleNode}</UpperHeading>
        <Heading className="mb-5 text-center md:text-left">{titleNode}</Heading>
        <Paragraph className="text-center md:text-left">{subtitleNode}</Paragraph>
        {callToActionNode && <div className="mt-8 text-center md:text-left">{callToActionNode}</div>}
      </div>
      <div className="order-1 w-full px-5 text-center md:order-2 md:w-1/2">
        <div
          className={
            imagePseudoSchema +
            " page-banner-image relative mx-auto inline-block max-h-[570px] max-w-[250px] sm:max-w-[60%] md:max-w-full"
          }>
          <img className="relative max-h-[250px] max-w-full md:max-h-full" src={imageSrc} alt="" />
        </div>
      </div>
    </div>
  );
};
