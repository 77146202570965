import { AlertTriangle } from "@calcom/ui/components/icon";

function STBookingError({ errors }: { errors: string[] }) {
  const getCustomMessages = (errors: string[]) => {
    return (
      <ul>
        {errors.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    );
  };
  if (!errors || errors?.length < 1) {
    return null;
  }
  return (
    <div data-testid="booking-fail" className="mt-2 border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertTriangle className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ltr:ml-3 rtl:mr-3">
          <div className="text-sm text-yellow-700">{getCustomMessages(errors)}</div>
        </div>
      </div>
    </div>
  );
}

export default STBookingError;
