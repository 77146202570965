import classNames from "classnames";

const UserInfoRow = ({
  label,
  value,
  type,
}: {
  label: string;
  value: string | number | null | undefined;
  type?: "info" | "error" | "warn" | "default";
}) => {
  return (
    <tr className="p-0 text-sm">
      <td className="p-0">
        <p className="">{label}:</p>
      </td>
      <td
        className={classNames(
          "font-regular py-0 pl-2 font-normal",
          type === "info" && "text-green-500",
          type === "warn" && "text-orange-500",
          type === "error" && "text-red-500"
        )}>
        <p className={classNames(!value && "")}>{value || "---"}</p>
      </td>
    </tr>
  );
};

export default UserInfoRow;
