export const SmallText = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <p
      className={
        "font-av text-stprimary-gray text-[10px] font-normal leading-4 md:text-xs md:leading-4" +
        " " +
        className
      }>
      {children}
    </p>
  ) : null;
};
