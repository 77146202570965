import { jointStatusTranslations } from "@stss/configs";
import { fetchUserSurveyAnswers } from "@stss/core";
import type {
  STUser,
  UserAnswersResponse,
  UserJoint,
  LicensedState,
  UserAnswersPayload,
  AdditionalInfo,
} from "@stss/types";
import { UserAnswers, UserInfoRow, UserJoints, VisitCause } from "@stss/ui";
import { useTranslation } from "next-i18next";
import { useState, useEffect } from "react";

import type { BookingItem } from "@calcom/web/components/booking/BookingListItem";

export type AdditionalPatientInfoProps = {
  booking: BookingItem;
  additionalInfo: AdditionalInfo | undefined;
  states: LicensedState[];
  joints: UserJoint[];
  bookingJoint: UserJoint | undefined;
};

export type UserDetailsDataPayload = {
  id?: number;
  username?: string;
  role?: string;
  stUserId?: number | number[];
  stUserEmail?: string;
};

function AdditionalPatientInfo({
  booking,
  states,
  bookingJoint,
  joints,
  additionalInfo,
}: AdditionalPatientInfoProps) {
  const { t } = useTranslation();
  const { user } = booking;
  const [patient, setPatient] = useState<STUser | null>(null);
  const [answers, setAnswers] = useState<UserAnswersResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [currentJoint, setCurrentJoint] = useState<UserJoint | undefined>(undefined);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const getAnswers = () => {
    if (!user?.id || !user?.email || !booking.stUserId || !patient) return;
    const arrayOfPromises: Promise<UserAnswersResponse>[] = [];
    const asaSurveyAnswersPayload: UserAnswersPayload = {
      stUserId: booking.stUserId,
      id: user.id,
      username: user.email,
    };
    arrayOfPromises.push(fetchUserSurveyAnswers(asaSurveyAnswersPayload));
    if (booking.jointId !== 0) {
      const onboardingAnswersPayload: UserAnswersPayload = {
        stUserId: booking.stUserId,
        id: user.id,
        username: user.email,
        jointId: booking.jointId,
      };
      arrayOfPromises.push(fetchUserSurveyAnswers(onboardingAnswersPayload));
    }
    setLoading(true);
    Promise.allSettled(arrayOfPromises)
      .then((res) => {
        const answers: UserAnswersResponse = {
          onboarding_survey_answers: [],
          asa_survey_answers: [],
        };
        const [asaAnswers, onboardingAnswers] = res;
        if (asaAnswers && asaAnswers?.status === "fulfilled") {
          answers.asa_survey_answers = asaAnswers.value.asa_survey_answers;
        }
        if (onboardingAnswers && onboardingAnswers?.status === "fulfilled") {
          answers.onboarding_survey_answers = onboardingAnswers.value.onboarding_survey_answers;
        }
        setAnswers(answers);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!additionalInfo) return;
    setLoading(additionalInfo.status === "loading");
    if (additionalInfo.status === "error") {
      setError(true);
      setLoading(false);
      return;
    }
    if (additionalInfo.status === "success" && additionalInfo.info) {
      const info = additionalInfo.info;
      const state = states?.find((state) => state.abbreviation === info?.residence_state);
      if (Array.isArray(info.joints)) {
        const updatedJoints = info.joints.map((userJoint) => {
          const fullJoint = joints.find((joint) => joint.id == userJoint.id);
          return {
            ...fullJoint,
            ...userJoint,
            statusDisplayName: t(jointStatusTranslations[userJoint.status]),
            current: userJoint.id == booking.jointId,
          };
        });
        info.joints = updatedJoints;
        if (booking.jointId) {
          setCurrentJoint(updatedJoints?.find((el) => el.id == booking.jointId));
        }
      }
      setLoading(false);
      setPatient({ ...info, residence_state_name: state ? state.name : "" });
    }
  }, [additionalInfo?.status]);

  return (
    <div className="h-full cursor-default text-sm" onClick={(event) => event.stopPropagation()}>
      <p className="text-sm font-medium leading-6">
        <span className="mr-1">{t("member_info")}</span>:
      </p>
      <>
        <table>
          <tbody>
            {additionalInfo && (
              <>
                <UserInfoRow
                  label={t("member_location")}
                  value={
                    booking.state ? booking.state?.abbreviation + " (" + booking.state?.name + ")" : null
                  }
                />
                <UserInfoRow label={t("member_id")} value={booking.stUserId} type="default" />
                <UserInfoRow label={t("name")} value={additionalInfo.stUserName} type="default" />
                <UserInfoRow label={t("email")} value={additionalInfo.email} type="default" />
                <UserInfoRow
                  label="Event type"
                  value={booking.eventType.length + "min visit"}
                  type={booking.eventType.length === 40 ? "info" : "warn"}
                />
                <VisitCause booking={booking} additionalInfo={additionalInfo} currentJoint={currentJoint} />
              </>
            )}
            {patient && !collapsed && (
              <>
                <UserInfoRow label={t("member_dob")} value={patient.birthdate} />
                <UserInfoRow label={t("member_gender")} value={patient.gender} />
              </>
            )}
          </tbody>
        </table>
        {patient && !collapsed && (
          <>
            <UserJoints label={t("member_subscriptions")} joints={patient.joints} />
            <UserAnswers
              loadAnswers={getAnswers}
              answers={answers}
              label={t("member_answers")}
              currentJoint={currentJoint}
            />
          </>
        )}
      </>
      {/* {loading && (
        <div className="relative mt-1 h-6 w-1/3">
          <FullScreenLoader spinnerSize={6} spinnerColor="blue-700" />
        </div>
      )} */}
      <p className="my-auto mt-1 font-normal">
        {error && <span className="text-red-500">{t("member_no_info")}</span>}
        {loading && <span className="animate-pulse text-yellow-500">{t("loading")}</span>}
        {!loading && patient && collapsed && (
          <span className="cursor-pointer text-blue-500 underline" onClick={() => setCollapsed(false)}>
            {t("more")}
          </span>
        )}
      </p>
    </div>
  );
}
export default AdditionalPatientInfo;
