import type { FormattedAddress } from "@stss/types";
import React from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
interface LatLng {
  lat: number;
  lng: number;
}

interface AutocompletionRequest {
  bounds?: [LatLng, LatLng];
  componentRestrictions?: { country: string | string[] };
  location?: LatLng;
  offset?: number;
  radius?: number;
  types?: string[];
}

export type GooglePlaceProps = {
  onChangeHandler: (address: FormattedAddress) => void;
  debounce: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  selectProps: any;
  autocompletionRequest?: AutocompletionRequest;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export function parseAddress(addresses: any[]) {
  const toReturn: FormattedAddress = {
    place_id: "",
    formatted_address: "",
    zip_code: "",
    city: "",
    state: "",
    address1: "",
    address2: "",
  };
  if (addresses && Array.isArray(addresses)) {
    const address = addresses[0];
    toReturn.place_id = address.place_id;
    toReturn.formatted_address = address.formatted_address;
    let street = "";
    let number = "";
    address.address_components.forEach((item: any) => {
      if (item.types.includes("postal_code")) {
        toReturn.zip_code = item.long_name;
      }
      if (item.types.includes("administrative_area_level_1") && item.types.includes("political")) {
        toReturn.state = item.short_name;
      }
      if (
        (item.types.includes("locality") && item.types.includes("political")) ||
        (item.types.includes("political") && item.types.includes("neighborhood"))
      ) {
        toReturn.city = item.long_name;
      }
      if (item.types.includes("route")) {
        street = item.long_name;
      }
      if (item.types.includes("street_number")) {
        number = item.long_name;
      }
    });
    toReturn.address1 = street.length > 0 ? number + " " + street : street;
  }
  return toReturn;
}

async function onchange(data: any, clb: (address: FormattedAddress) => void) {
  const details = await geocodeByPlaceId(data.value.place_id);
  const parsed = parseAddress(details);
  clb(parsed);
}

// function onLoadFailed(error: any) {
//   console.log("onLoadFailed", error);
// }

function GooglePlaceAutocompleteInput(props: GooglePlaceProps) {
  const { onChangeHandler, debounce, selectProps, autocompletionRequest } = props;

  if (apiKey) {
    return (
      <div className="custom-address-input">
        <GooglePlacesAutocomplete
          apiKey={apiKey}
          apiOptions={{ language: "en", region: "us" }}
          debounce={debounce || 300}
          selectProps={{
            className: "react-select-container",
            ...selectProps,
            onChange: async (data) => {
              await onchange(data, onChangeHandler);
            },
          }}
          autocompletionRequest={autocompletionRequest}
        />
      </div>
    );
  } else {
    return <p className="text-red-600">Please provide NEXT_PUBLIC_GOOGLE_API_KEY to env. file</p>;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default GooglePlaceAutocompleteInput;
