import { Translate, SocialLinks, SubscriptionForm } from "@stss/ui";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import type { SyntheticEvent } from "react";

import { SmallText } from "./typography";
import { Logo } from "./ui";

export type FooterColumn = {
  columnName: string;
  items: { title: string; link: string }[];
};

export type FooterProps = {
  websiteLink: string;
  supportEmail: string;
  providerEmail: string;
  footerMenu: FooterColumn[];
};

export const Footer = ({ websiteLink, footerMenu, supportEmail, providerEmail }: FooterProps) => {
  const { t } = useTranslation();
  const imagePath = "/st/img/icons/";

  const subject = "Looking to join SimpleTherapy Clinical Team";
  const body = "Looking to join SimpleTherapy Clinical Team";

  const contacts = [
    {
      icon: "phone_icon-min.png",
      title: "1-800-644-2478 TOLL FREE",
      link: `mailto:${providerEmail}?subject=${subject}&body=${body}`,
    },
    { icon: "mail_icon-min.png", title: supportEmail, link: "mailto:" + supportEmail },
    { icon: "map_icon-min.png", title: t("st_address"), link: null },
  ];

  const getLink = (link: string) => {
    return link.includes("http") ? link : websiteLink + link;
  };

  const toggleList = (event: SyntheticEvent) => {
    const list = event.currentTarget?.nextElementSibling;
    const triangle = event.currentTarget.querySelector("span");
    if (list) {
      list.classList.toggle("h-0");
    }
    if (triangle && list) {
      triangle.classList.toggle("rotate-180", !list.classList.contains("h-0"));
    }
  };

  return (
    <footer className="py-9">
      <div className="container m-auto px-5 2xl:max-w-[1140px]">
        <div className="mb-6 grid gap-5 lg:grid-cols-3">
          <div className="flex flex-col items-center text-center">
            <div className="mb-3 w-[300px]">
              <Logo size="large" logoLink={websiteLink} logoTitle={t("website") + " " + t("st_brand_name")} />
            </div>
            <SmallText className="mb-5">
              <Translate i18nKey="company_offers_text" />
            </SmallText>
            <div className="flex justify-center">
              <ul className="list-none">
                {contacts.map((contact) => {
                  return (
                    <li
                      key={contact.link}
                      className="font-av text-stprimary-gray mb-3 flex items-center text-base font-normal lg:text-lg">
                      <span className="mr-5 w-5 shrink-0 text-center">
                        <img className="inline-block" src={imagePath + contact.icon} alt={contact.title} />
                      </span>
                      {contact.link ? (
                        <a href={contact.link} className="hover:text-stprimary-orange-900 text-left">
                          {contact.title}
                        </a>
                      ) : (
                        <span className="text-left">{contact.title}</span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-span-2 mb-6">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              {footerMenu.map((menuItem, i) => {
                return (
                  <div className="mb-2 flex justify-center" key={i}>
                    <div className="text-center md:text-left">
                      <h4
                        className="font-av text-stprimary-blue  mb-3 flex cursor-pointer select-none items-center justify-center font-semibold uppercase md:cursor-default md:justify-start"
                        onClick={toggleList}>
                        {menuItem.columnName}
                        <span className="ml-3 h-4 w-4 md:hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 rotate-90"
                            fill="var(--brand-blue)"
                            viewBox="0 0 24 24"
                            strokeWidth={0}
                            stroke="currentColor">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                            />
                          </svg>
                        </span>
                      </h4>
                      <ul className="h-0 list-none overflow-hidden md:h-full">
                        {menuItem.items.map((item) => {
                          return (
                            <li className="mb-1" key={item.link}>
                              <Link
                                href={getLink(item.link)}
                                className="font-av text-stprimary-gray hover:text-stprimary-orange-900 text-base font-normal lg:text-lg"
                                target="_blank"
                                rel="noreferrer">
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="m-auto mb-6 grid max-w-screen-md grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
          <div className="mb-4 text-center md:mb-0">
            <h4 className="font-av text-stprimary-gray mb-3 text-base font-normal font-semibold lg:text-lg">
              {t("company_subscribe_title")}
            </h4>
            <SubscriptionForm placeholder={t("email_placeholder")} buttonText={t("company_subscribe_cta")} />
          </div>
          <div className="text-center">
            <h4 className="font-av text-stprimary-gray mb-3 text-base font-normal font-semibold lg:text-lg">
              {t("company_follow_us")}
            </h4>
            <SocialLinks />
          </div>
        </div>
        <div className="font-av text-stprimary-gray text-center">
          <SmallText className="mb-3">
            <Translate i18nKey="company_copy_text_1" />
          </SmallText>
          <SmallText>
            <Translate
              i18nKey="company_copy_text_2"
              values={{
                year: new Date().getFullYear(),
              }}
            />
          </SmallText>
        </div>
        <div className="mt-6 hidden justify-center md:flex">
          <div className="mr-6">
            <img className="max-h-20 max-w-full" src="/st/img/logo/hippa-compilant.png" alt="HIPPA" />
          </div>
          <div className="">
            <a
              href="https://certs.assureprofessional.com/certs/23496135.html"
              target="_blank"
              rel="noreferrer">
              <img className="max-h-20 max-w-full" src="/st/img/logo/soc.png" alt="SOC" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
