import * as AlertDialog from "@radix-ui/react-alert-dialog";
import type { NextRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { useLocale } from "@calcom/lib/hooks/useLocale";
import { trpc } from "@calcom/trpc/react";
import { Info } from "@calcom/ui/components/icon";

export const removeQueryParamsFromRouter = (router: NextRouter, removeList: string[] = []) => {
  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    { shallow: true }
  );
};

interface IDialogProps {
  bookingUId: string | null;
}

function RescheduleCheckingDialog(props: IDialogProps) {
  // const router = useRouter();
  const { t } = useLocale();
  const { bookingUId: bookingId } = props;

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const rescheduledBookingQuery = trpc.viewer.bookings.getByUid.useQuery({
    uid: bookingId || "",
  });

  useEffect(() => {
    if (rescheduledBookingQuery.isFetching) return;
    if (bookingId && !rescheduledBookingQuery.data?.booking?.id) {
      setIsOpenDialog(true);
      // removeQueryParamsFromRouter(router, ["rescheduleUid"]);
    }
  }, [rescheduledBookingQuery.isFetching]);

  return (
    <AlertDialog.Root open={isOpenDialog}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fadeIn fixed inset-0 z-40 bg-gray-800 bg-opacity-75 transition-opacity" />
        <AlertDialog.Content className="fadeIn fixed left-1/2 top-1/2 z-[9998] max-h-[95vh] min-w-[360px] -translate-x-1/2 -translate-y-1/2 overflow-visible overflow-visible rounded bg-white p-8 text-left shadow-xl focus-visible:outline-none sm:w-full sm:max-w-[35rem] sm:align-middle">
          <div className="flex flex-row space-x-3">
            <div className="flex h-10 w-10 flex-shrink-0 justify-center rounded-full bg-[#FAFAFA] text-orange-700 ">
              <Info className="m-auto h-6 w-6" />
            </div>
            <div className="w-full pt-1">
              <div className="mb-4">
                <h3 className="leading-20 text-semibold font-cal pb-1 text-xl text-gray-900">
                  {t("reschedule_visit_impossible")}
                </h3>
              </div>
              <p className="text-sm text-gray-500">{t("reschedule_visit_impossible_description")}</p>
              {/* <div className="mt-3 flex justify-end">
                <AlertDialog.Action asChild>
                  <Button>{t("close")}</Button>
                </AlertDialog.Action>
              </div> */}
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

export default RescheduleCheckingDialog;
