export const CardText = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return children ? (
    <p
      className={
        "font-av text-center text-base font-normal leading-[22px] lg:text-[22px] lg:leading-8" +
        " " +
        className
      }>
      {children}
    </p>
  ) : null;
};
